import React from "react";
import "./FeaturedImage.css";

class FeaturedImage extends React.Component {
  render() {
    return (
      <div
        style={{
          height: "30em",
          width: "100%",
          position: "relative",
          marginBottom:"64px"
        }}
      >
        <img
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
          src={this.props.featuredImage}
          alt="featured_image"
        />
        <div
          className="featured-image-overlay"
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(41, 41, 41, 0.6)",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            letterSpacing: "6px",
            fontSize: "48px",
            fontWeight: "bold",
          }}
        >
          {this.props.label}
        </div>
      </div>
    );
  }
}

export default FeaturedImage;
