import React from "react";
import Header from "../components/Header";
import FeaturedImage from "../components/FeaturedImage";
import RestaurantCard from "../components/RestaurantCard";
import withRouter from "../utilities/withRouter";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axios from 'axios';

class CategoryPage extends React.Component{
    constructor(props){
        super(props)
        
        this.state = {
            restaurantDetails:[],
            category_data:[],
            restaurant_categories:[],
            categoryID: 0,
            store_final_restaurant:[],
        }
       
    }

    componentDidMount() {
        // let categoryID = this.props.router.params.id;


            axios.get('https://wte.the-oaks.my/api.php')
            .then(response => {

                // let filterRestaurant = [];

                // response.data.forEach((allRestaurantDetail,index) => {
                //     if(allRestaurantDetail.id === this.state.restaurant_categories.restaurant_id){
                //         filterRestaurant[index] = allRestaurantDetail;
                //     }
                // })
                this.setState({restaurantDetails: response.data})
                // console.log("testing",filterRestaurant)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
            ///-------
            axios.get('https://wte.the-oaks.my/restaurant_category.php')
            .then(response => {

                let selectedRestaurant = [];
                response.data.forEach((eachRestaurant,index) => {
                    if(eachRestaurant.category_id === this.props.router.params.id){
                        selectedRestaurant.push(eachRestaurant);
                    }else{
                        return;
                    }
                });
                this.setState({restaurant_categories: selectedRestaurant})
                // console.log("halo",selectedRestaurant)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
                ///-----------------------
            axios.get('https://wte.the-oaks.my/categories.php')
            .then(response => {
                // console.log("connected");
                // console.log("API Response:", response.data); 
                let selectedCategory = {};
                response.data.forEach(eachCategory => {
                    if(eachCategory.category_id === this.props.router.params.id){
                        selectedCategory = eachCategory;
                    }
                });
                this.setState({ category_data: selectedCategory });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    
        
    }

    render(){
        // console.log("all details", this.state.restaurantDetails)
        // console.log("category res id", this.state.restaurant_categories)

        const restaurantIds = this.state.restaurant_categories.map(restaurant => restaurant.restaurant_id);

        // Filter the restaurantDetails array based on the extracted restaurant IDs
        const filteredRestaurants = this.state.restaurantDetails.filter(
            data => restaurantIds.includes(data.id)
        );
        
        console.log("filter result",filteredRestaurants)
        return(
            <div>
                <Header/>
                <FeaturedImage featuredImage={this.state.category_data.image} label={this.state.category_data.type} />
                
                {/* {this.state.restaurantDetails.filter(data => data.id === this.state.restaurant_categories.restaurant_id)              
                 .map((details,index) =>(
                    <RestaurantCard 
                        key={index}
                        image = {details.image}
                        name = {details.name}
                        address = {details.address}
                        rate = {details.rate}
                    />
                ))} */}

                {filteredRestaurants.map((details, index) => (
                    <Link
                        key={index}
                        to={{
                        pathname: `/direction/${details.id}`}}
                        style={{ textDecoration: 'none' }}
                    >
                        <RestaurantCard 
                            key={index}
                            image={details.image}
                            name={details.name}
                            address={details.address}
                            rate={details.rate}
                        />
                    </Link>
               
            ))}
                
                

                {/* {this.state.restaurantCardDetails.map((details,index)=>(
                    <RestaurantCard key={index} 
                    image={details.restaurantImage}
                    name={details.name}
                    address={details.address}
                    operationHours={details.operationHours}/>        
                ))} */}

                
                {/* {this.state.restaurantDetails.filter(detail => detail.id === this.props.data.category_id).map((filteredData,index)=>(
                    <div style={{marginBottom:"48px"}} key={index} >
                        <Link  to={{
                            pathname: `/direction/${filteredData.id}`,
                            state: { 
                                data: filteredData 
                            } // Pass the data object as state
                        }} style={{textDecoration:"none"}}>
                     <RestaurantCard
                        id = {filteredData.id}
                        image={filteredData.image}
                        name={filteredData.name}
                        address={filteredData.address}
                        rate={filteredData.rate}
                        lat={filteredData.lat}
                        long={filteredData.longitude}
                        />  
                    </Link>  
                        </div>
                       
                ))} */}
                


               
                

                <Footer/>
            </div>
        )
    }
}

export default withRouter(CategoryPage);