import React from 'react';
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import MainPage from "../src/pages/MainPage";
import CategoryPage from './pages/CategoryPage';
import DirectionPage from './pages/DirectionPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage/>} />
        <Route path="/category/:id" element={<CategoryPage/>} />
        <Route path="/direction/:id" element={<DirectionPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
