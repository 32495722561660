
import '../pages/MainPage.css';
import CategoryCard from '../components/CategoryCard'; 
import TopRateRestaurantCard from '../components/TopRateRestaurantCard';
import Header from '../components/Header';
import FeaturedImage from '../components/FeaturedImage';
import Footer from '../components/Footer';
import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: [],
        categories: [],
        selectedCategory: null, 
    };
  };

  state = {
    dataToPass: "data passed"
  }

  handleCategoryCardClick = (categoryId) => {
    this.setState({ selectedCategory: categoryId });
    
  };
  
  
  componentDidMount() {

    axios.get('https://wte.the-oaks.my/api.php')
        .then(response => {
            
            this.setState({ data: response.data });
        })
        ;

  axios.get('https://wte.the-oaks.my/categories.php')
  .then(response => {
      console.log("API response:", response.data);
      this.setState({ categories: response.data });
  })
  ;

  axios.get('https://wte.the-oaks.my/categories.php')
            .then(response => {
               
                this.setState({ retaurant_data: response.data });
            })
           ;
    
        
}

  render() {
  
    const { data } = this.state;

    const { categories } = this.state;

    // const categories = [
    //   { name: "Fast Food", image: FastFoodImage },
    //   { name: "Chinese", image: ChineseImage },
    //   { name: "Fast Food", image: FastFoodImage },
    //   { name: "Chinese", image: ChineseImage },
    //   { name: "Fast Food", image: FastFoodImage },
    //   { name: "Chinese", image: ChineseImage },
  
    // ];

    // const TopRateRestaurant = [
    //     { name: "Fast Food", image: FastFoodImage },
    //     { name: "Chinese", image: ChineseImage },
    //     { name: "Fast Food", image: FastFoodImage },
    //     { name: "Chinese", image: ChineseImage },
    //     { name: "Fast Food", image: FastFoodImage },
    //     { name: "Chinese", image: ChineseImage },
    //     { name: "Fast Food", image: FastFoodImage },
    //     { name: "Chinese", image: ChineseImage },
    
    //   ];
  
    return (
      <>
      <Header />
      <FeaturedImage featuredImage="https://img.freepik.com/free-photo/chicken-wings-barbecue-sweetly-sour-sauce-picnic-summer-menu-tasty-food-top-view-flat-lay_2829-6471.jpg?w=2000" label="homepage"/>
      <div style={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", fontSize: "18px", fontWeight: "bold", margin: "20px 0" ,color:"black"}}>Category</div>

            <div className="category_container">
            {categories.map((category, index) => (
          <Link
          key={index}
          to={{
            pathname: `/category/${category.category_id}`}}
          style={{ textDecoration: 'none' }}
          >
          <CategoryCard
            name={category.type}
            image={category.image}
            category_id={category.category_id}
          />
          {/* <Bridge data={category}/> */}
        </Link>
        
      ))}
            </div>
            <p style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center", fontSize: "18px", fontWeight: "bold", margin: "20px 0" ,maxWidth:" -webkit-fill-available" }}>Top Rate Restaurant</p>
            <div style={{display:"flex", justifyContent:"center",background: "#D9D9D9"}}>
            <div style={{ display: "grid", gridTemplateColumns: 'repeat(4, minmax(200px, 1fr))', gap: "20px", padding: "20px 0", borderRadius: "8px" , overflowY:"hidden" ,maxWidth:"1024px"}}>
            {data
            .sort((a, b) => b.rate - a.rate)
            .slice(0, 8)
            .map((restaurant, index) => (
              <Link
          key={index}
          to={{
            pathname: `/direction/${restaurant.id}`,
            state: {
              data: restaurant, // Pass the restaurant object as state
            }
          }}
          style={{ textDecoration: 'none' }}
        >
          <TopRateRestaurantCard
            name={restaurant.name}
            image={restaurant.image}
            restaurant_id={restaurant.id} // Assuming you need the restaurant ID
          />
        </Link>
      ))}
            </div>       
            </div>          
        </div>
       <Footer />
       </>
    );
  }
}
export default MainPage; 
