import React from "react";
import Logo from "../images/Logo.png";
import "./Footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div
        className="footer-container"
        style={{
          backgroundColor: "#FCF5EE",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent:"space-around",
          bottom: 0,
          width: "100%", 
        }}
      >
        <img
          style={{
            display: "flex",
            width: "200px",
          }}
          src={Logo}
          alt="Logo"
        />
        <div className="footer-text-container" style={{paddingLeft:"24px", display:"flex"}}>
        <div style={{textAlign:"center", fontSize:"24px", fontWeight:"bold" }}>Savor Every Bite: Your Culinary Adventure Awaits!</div>
        </div>
      </div>
    );
  }
}

export default Footer;
