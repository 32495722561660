import Header from '../components/Header';
import FeaturedImage from '../components/FeaturedImage';
import Footer from '../components/Footer';
import axios from 'axios';
import React, { Component } from 'react';
import withRouter from '../utilities/withRouter';
// import { useLocation } from 'react-router-dom';



class DirectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      restaurantID: 0,
      restaurant_data: [], // Corrected state variable name
      
    };
  };

  componentDidMount() {
    const restaurantID = this.props.router.params.id;
  
   
    axios.get('https://wte.the-oaks.my/api.php')
      .then(response => {
        const selectedRestaurant = response.data.find(restaurant => restaurant.id === restaurantID);

        if (selectedRestaurant) {
          this.setState({ restaurant_data: selectedRestaurant });
        } else {
          console.error('Restaurant not found with ID:', restaurantID);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  

  render() {


    // console.log("testing", this.state.data[3]);
    // const { data } = this.state;
     console.log(this.props.router.params.id)
     const { restaurant_data } = this.state;
     console.log(restaurant_data)
    return (
      <>
      <Header />
      <FeaturedImage featuredImage={restaurant_data.image} label="Direction" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '48px' }}>
        <iframe
          title='Map'
          src={`https://www.google.com/maps/?q=${restaurant_data.lat},${restaurant_data.longitude},${restaurant_data.name}&hl=en&output=embed`} 
          width="600"
          height="450"
          style={{ border: '0', width: '84%' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.788397451991!2d101.57267821236466!3d3.1504432531276794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1
        !3m3!1m2!1s0x31cc4fa4ce1ec04f%3A0xf8d614c9dbd05161
        !2sJC%20Restaurant!5e0!3m2!1sen!2smy!4v1691740784502!5m2!1sen!2smy" 
        width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </div>
      <Footer />
    </>
  );
}
}

export default withRouter(DirectionPage);




