import React from "react";
// import { Link } from "react-router-dom";
import "./RestaurantCard.css"

class RestaurantCard extends React.Component {
  render() {
    return (    
      <div
        style={{
          padding: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="restaurant-card-container"
          style={{
            backgroundColor: "#FCF5EE",
            maxWidth: "1024px",
            width: "100%",
            padding: "24px",
            display: "flex",
            height: "300px",
            borderRadius: "12px",
            overflow: "hidden"
          }}
        >
          <img
            style={{
              width: "35%",
              aspectRatio: "1",
              objectFit: "cover",
            
            }}
            src={this.props.image}
            alt="restaurant"
          />
          <div className="restaurat-card-details-container" style={{ display:"flex", flexDirection:"column", marginLeft: "12px", height:"100%", width:"100%", color:"black"}}>
            <p className="restaurant-card-name"
              style={{
                margin: "unset",
                fontSize: "2em",
                fontWeight: "bold",
                padding: "unset",
                display: "flex",
              }}
            >
              {this.props.name}
            </p>

            <div style={{ height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
              <p style={{ textAlign:"justify", fontSize: "18px", width:"100%"}}>
                {this.props.address}
              </p>
              
              <div>
               Rate: {this.props.rate} / 5.0
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default RestaurantCard;
