import React, { Component } from 'react';

class TopRateRestaurantCard extends Component {
  render() {
    const { image, name } = this.props;

    return (
     
        <div style={{
          borderRadius: '90px',
          padding: '20px',
          textAlign: 'center',
          color: 'black',
          textDecoration: 'none',
        }}>
          <img src={image} alt={name} style={{borderRadius:"50%", width:"144px",height:"144px"}} />
          <p>{name}</p>
        </div>
  
    );
  }
}

export default TopRateRestaurantCard;
