import React from "react";
import Logo from "../images/Logo.png";
import "./Header.css";

class Header extends React.Component {
  render() {
    return (
      <div
        className="header-container"
        style={{
          backgroundColor: "#FCF5EE",
          height: "80px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "24px",
        }}
      >
        <img
          style={{
            display: "flex",
            width: "200px",
          }}
          src={Logo}
          alt="Logo"
        />
      </div>
    );
  }
}

export default Header;
