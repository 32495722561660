import React, { Component } from 'react';
class CategoryCard extends Component {
  render() {
    const { image, name } = this.props;
    return (
    
        <div style={{
          backgroundColor: '#FCF5EE',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          textAlign: 'center',
          height: '170px',
          width: '140px',
          paddingBottom: '10px',
          cursor: 'pointer',
          color: 'black',
          textDecoration: 'none',
        }}>
          <img src={image} alt={name} style={{width:"100px",borderRadius:"50%"}} />
          <p>{name}</p>
        </div>

    );
  }
}

export default CategoryCard;
